import { saveAs } from "file-saver"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import illustrator from "../../../assets/images/ai.png"
import photoshop from "../../../assets/images/psd.png"
import font from "../../../assets/images/tt.png"
import { deleteEntry, getCategoryNames } from "../../../services/CMS"
import Button from "../../atoms/button/button"
import UploadImage from "../../organisms/upload-image/upload-image"
import "./card-modal.scss"
import userSessionStorage from "../../../services/userSessionStorage"
import { isAdmin } from "../../../utils/getRol"

export default ({ data, setIsModalVisible }) => {
  const session = new userSessionStorage()

  const { url } = data.fields.recurso.fields.file
  const { nombreDelPais } = data.fields.pais.fields
  const { description } = data.fields.recurso.fields
  const { value } = data.fields.descripcion.content[0].content[0]
  const { nombre, tipoContenido, etiquetas } = data.fields
  const entryId = data.sys.id
  // console.info("ENTRY ID", entryId)
  const assetId = data.fields.recurso.sys.id
  const subcategory = data.fields.subcategoria.fields.nombre
  const categoryId = data.fields.subcategoria.fields.categoria.sys.id
  const { contentType, fileName } = data.fields.recurso.fields.file
  const [category, setCategory] = useState("")
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const [role, setRole] = useState("user")

  useEffect(() => {
    const getData = async () => {
      const res = await getCategoryNames()
      const categoryName = res.items.filter(el => el.sys.id === categoryId)[0]
        .fields.nombre

      setCategory(categoryName)
    }

    // console.info("TIPO", tipoContenido)
    getData()

    return () => {
    }
  }, [])


  useEffect(() => {
    // get ROle
    let idToken = JSON.parse(localStorage.getItem("TOKEN_INFO"))?.idToken

    if (!(session.getToken() === null)) {
      // sesion NOL
      if (isAdmin(idToken)) {
        setRole("admin")
      }

    } else {
      // sesion Familia
      if (JSON.parse(sessionStorage.getItem("userSession")).rol === "admin") {
        setRole("admin")
      }
    }
  }, [])

  const renderPreview = () => {
    if (contentType.includes("video")) {
      return (
        <video controls width="100%">
          <source src={url} type={contentType} />
          {/* Sorry, your browser doesn't support embedded videos. */}
        </video>
      )
    } else if (fileName.includes(".ai")) {
      return <img src={illustrator} alt={description} />
    } else if (fileName.includes(".psd")) {
      return <img src={photoshop} alt={description} />
    } else if (contentType.includes("font")) {
      return <img src={font} alt={description} />
    }

    return <img src={url} alt={description} />
  }

  return ReactDOM.createPortal(
    <div className="card-modal-container card-modal-container--scrollable">
      {isDeleting ? (
        <div className="card-modal-wrapper popup">
          <div className="card-modal-content">
            <div className="card-modal-icon-wrapper">
              <div className="card-modal-icon" />
            </div>
            <div className="content-data-icon">
              <div
                className="close-icon"
                onClick={() => {
                  document.body.classList.remove("modal-open")
                  setIsModalVisible(false)
                }}
              />
            </div>
            <h1>Eliminar archivo</h1>
            <p>¿Está seguro que quiere eliminar este archivo?</p>
            <div className="card-modal-buttons">
              <Button
                name="Cancelar"
                styleType="primary-inverse"
                onClick={() => {
                  document.body.classList.remove("modal-open")
                  setIsModalVisible(false)
                }}
              />
              <Button
                name="Sí, estoy seguro"
                styleType="primary"
                onClick={async () => {
                  await deleteEntry({ entryId, assetId })
                  document.body.classList.remove("modal-open")
                  setIsModalVisible(false)
                  window.location.href = "/"
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="card-modal-wrapper card-modal-wrapper--scrollable">
          <div className="card-modal-content">
            <div className="content-image">{renderPreview()}</div>
            <div className="content-data">
              <div className="content-data-icon">
                <div
                  className="close-icon"
                  onClick={() => {
                    document.body.classList.remove("modal-open")
                    setIsModalVisible(false)
                  }}
                />
              </div>
              {role === "admin" ? (
                <div className="content-data-admin">
                  <div
                    className="edit-icon"
                    onClick={() => {
                      setIsEditing(true)
                    }}
                  />
                  {isEditing && (
                    <UploadImage
                      data={data}
                      category={category}
                      setIsModalVisible={setIsModalVisible}
                    />
                  )}
                  <div
                    className="trash-icon"
                    onClick={() => setIsDeleting(true)}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="content-data-header">
                <span>{nombre}</span>
                <span>{nombreDelPais}</span>
              </div>
              <div className="content-data-description">
                <span> Descripción: </span>
                <p>{value}</p>
              </div>
              <div className="content-data-item">
                <span>Categoría:</span> {category}
              </div>
              <div className="content-data-item">
                <span>Subcategoría:</span> {subcategory}
              </div>
              <div className="tag-wrapper">
                <div className="tag-container">
                  {etiquetas
                    .filter(el => el.fields.estado)
                    .map((el, index) => (
                      <div key={index} className="tag">
                        {el.fields.nombreEtiqueta}
                      </div>
                    ))}
                </div>
              </div>
              {/* <a href={`https:${url}`} download onClick={() => download()}>
              Descargar
            </a> */}
              <Button
                styleType="primary"
                name="Descargar"
                onClick={() => {
                  saveAs(url, fileName, { type: contentType })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>,
    document.body,
  )
}

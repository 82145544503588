import React, { useState } from "react"
import { createAsset, getAsset } from "../../../services/CMS"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "./drag-and-drop-edit.scss"
import illustrator from "../../../assets/images/ai.png"
import photoshop from "../../../assets/images/psd.png"
import font from "../../../assets/images/tt.png"
export default ({ setAssetId, defaultImage, children }) => {
  const [assetInfo, setAssetInfo] = useState(
    defaultImage ? { url: renderPreview() } : {}
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    let file = [...e.dataTransfer.files][0]
    onFileSelect(file)
    setIsLoading(true)
  }
  function renderPreview() {
    if (defaultImage.contentType.includes("video")) {
      return defaultImage.url
    } else if (defaultImage.fileName.includes(".ai")) {
      return illustrator
    } else if (defaultImage.fileName.includes(".psd")) {
      return photoshop
    } else if (defaultImage.contentType.includes("font")) {
      return font
    }
    return defaultImage.url
  }
  const onFileSelect = file => {
    console.info(file)
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onloadend = async function(e) {
      if (
        file.type.includes("video") ||
        file.type.includes("image") ||
        file.type.includes("application/font-sfnt") ||
        file.name.includes(".psd") ||
        file.name.includes(".ai")
      ) {
        setIsValid(true)
        const asset = await createAsset({
          title: "Test",
          description: "Test description",
          contentType: file.type,
          fileName: file.name,
          file: e.target.result,
        })
        console.info("ASSET", asset)
        setAssetId(asset)
        const assetDetails = await getAsset({ assetId: asset })
        setAssetInfo(assetDetails)
      } else {
        console.info("FORMATO NO VÁLIDO")
        setAssetInfo({ ...assetInfo, url: undefined })
        setIsValid(false)
      }
      setIsLoading(false)
    }
  }
  return (
    <div className="content-image content-image-upload f-container-edit">
      <div className="upload-box-container">
        <div
          className="upload-box-content"
          onDrop={e => handleDrop(e)}
          onDragOver={e => handleDragOver(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragLeave={e => handleDragLeave(e)}
        >
          {assetInfo.url !== undefined &&
            isValid &&
            ((defaultImage.contentType !== undefined &&
              defaultImage.contentType.includes("video")) ||
            (assetInfo.contentType !== undefined &&
              assetInfo.contentType.includes("video")) ? (
              <video controls width="100%">
                <source
                  src={assetInfo.url}
                  type={defaultImage.contentType || assetInfo.contentType}
                />
                {/* Sorry, your browser doesn't support embedded videos. */}
              </video>
            ) : (
              <img src={assetInfo.url} />
            ))}
          {assetInfo.url === undefined && isLoading && (
            <>
              <Loader
                type="Circles"
                color="#FF409B"
                height={100}
                width={100}
                // timeout={3000} //3 secs
              />
              <label>El archivo está cargando</label>
            </>
          )}
          {assetInfo.url === undefined && !isLoading && (
            <>
              <input
                type="file"
                id="img-uploader"
                className="inputfile"
                onChange={e => {
                  const file = e.target.files[0]
                  onFileSelect(file)
                  setIsLoading(true)
                }}
              ></input>
              <label htmlFor="img-uploader">
                <div className="upload-icon"></div>
                {isValid
                  ? "Selecciona o arrastra el recurso"
                  : "Este formato no está permitido"}
              </label>
            </>
          )}
        </div>
      </div>
      {children}
    </div>
  )
}
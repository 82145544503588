import React, { useEffect } from "react"
import { XBlock, XMasonry } from "react-xmasonry"
import GridCard from "../grid-card/grid-card"
import "./grid.scss"

export default ({ data }) => {

  useEffect(() => {
    return () => { }
  }, [data])

  return (
    <XMasonry>
      {data.map((el, index) => (
        <XBlock key={index}>
          <GridCard data={el} />
        </XBlock>
      ))}
    </XMasonry>
  )
}
import React, { useState, useEffect } from "react"
import { createAsset, getAsset } from "../../../services/CMS"

import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "./drag-and-drop.scss"
import illustrator from "../../../assets/images/ai.png"
import photoshop from "../../../assets/images/psd.png"
import font from "../../../assets/images/tt.png"

var countFiles = 10

export default ({ setAssetId, defaultImage, children, resourcesUpload }) => {
  const [assetInfo, setAssetInfo] = useState(
    defaultImage ? { url: renderPreview() } : {}
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const [resourcesInfo, setResourcesInfo] = useState([])
  const [isValidCountFiles, setIsValidCountFiles] = useState(true)

  useEffect(() => {
    resourcesUpload(resourcesInfo)
  }, [resourcesInfo])

  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()

    if (resourcesInfo.length > 9) return

    const file = [...e.dataTransfer.files]

    if (file.length > 10) {
      setIsValidCountFiles(false)
      setIsLoading(false)
      return
    }

    onFileSelect(file.slice(0, countFiles))
    countFiles = countFiles - file.length

    setIsLoading(true)
  }

  function renderPreview(file = "") {
    const resource = file ? file : defaultImage

    if (resource.contentType.includes("video")) {
      return resource.url
    } else if (resource.fileName.includes(".ai")) {
      return illustrator
    } else if (resource.fileName.includes(".psd")) {
      return photoshop
    } else if (resource.contentType.includes("font")) {
      return font
    }

    return resource.url
  }

  const onFileSelect = file => {
    for (let i = 0; i < file.length; i++) {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file[i])

      reader.onloadend = function(e) {
        if (
          file[i].type.includes("video") ||
          file[i].type.includes("image") ||
          file[i].type.includes("application/font-sfnt") ||
          file[i].name.includes(".psd") ||
          file[i].name.includes(".ai")
        ) {
          setIsValid(true)

          const filesUpload = {
            contentType: file[i].type,
            fileName: file[i].name,
            size: file[i].size,
            file: e.target.result,
          }

          setResourcesInfo(resourcesInfo => [...resourcesInfo, filesUpload])

          setIsLoading(false)
        } else {
          console.info("FORMATO NO VÁLIDO")
          setAssetInfo({ ...assetInfo, url: undefined })
          setIsValid(false)
        }
      }
    }
  }

  const removeFile = index => {
    const element = resourcesInfo[index]
    const elementsResources = resourcesInfo.filter(
      resource => resource !== element
    )
    countFiles++
    setResourcesInfo(elementsResources)
  }

  return (
    <div className="content-image content-image-upload f-container-upload">
      <div className="upload-box-container">
        <div
          className="upload-box-content"
          onDrop={e => handleDrop(e)}
          onDragOver={e => handleDragOver(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragLeave={e => handleDragLeave(e)}
        >
          {assetInfo.url === undefined && isLoading && (
            <>
              <Loader type="Circles" color="#FF409B" height={60} width={60} />
              <label>El archivo está cargando</label>
            </>
          )}
          {assetInfo.url === undefined && !isLoading && (
            <>
              <input
                type="file"
                id="img-uploader"
                className="inputfile"
                onChange={e => {
                  e.preventDefault()

                  const file = [...e.target.files]

                  if (file.length > 10) {
                    setIsValidCountFiles(false)
                  }

                  onFileSelect(file.slice(0, countFiles))
                  countFiles = countFiles - file.length

                  setIsLoading(true)
                }}
                disabled={!isValidCountFiles || resourcesInfo.length > 9}
                multiple
              ></input>
              <label htmlFor="img-uploader">
                <div className="upload-icon"></div>
                {isValid
                  ? "Selecciona o arrastra el recurso"
                  : "Este formato no está permitido"}
              </label>
            </>
          )}
        </div>
      </div>
      {children}

      {!isValidCountFiles ||
        (resourcesInfo.length > 9 && (
          <div>
            <p className="f-message-count-giles">
              * Solo se pueden cargar máximo 10 archivos
            </p>
          </div>
        ))}

      {resourcesInfo && (
        <div className="f-container-preview-files">
          {resourcesInfo.map((file, index) => {

            const blob = new Blob([file.file], { type: file.contentType })

            const resource = {
              contentType: file.contentType,
              fileName: file.fileName,
              file: file.file,
              size: file.size,
              url: URL.createObjectURL(blob),
            }

            const src = renderPreview(resource)

            return (
              <div
                className={
                  "f-row-preview-file" +
                  (file.size > 7000000 ? " file-size" : "")
                }
                key={index}
              >
                <p>{file.fileName}</p>
                <div className="f-container-file-preview">
                  {file.contentType.includes("video") ? (
                    <video controls width="100%">
                      <source src={src} type={file.contentType} />
                    </video>
                  ) : (
                    <img src={src} />
                  )}
                </div>
                <div className="f-container-delete-file">
                  <div
                    className="trash-icon"
                    onClick={() => removeFile(index)}
                  ></div>
                </div>
                {file.size > 7000000 && (
                  <div className="f-container-error-size">
                    <p>El archivo debe pesar máximo 7mb</p>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

import React, { useState } from "react"

import "./radiobox.scss"

export default ({ name, options, onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || options[0])

  const handleChange = option => {
    console.info("RADIOBOX OPTION CLICKED", option, name)
    setValue(option)
  }

  return (
    <div className="radiobox-wrapper">
      <form>
        {options.map((option, index) => (
          <div key={option}>
            <label className="radiobox-label" htmlFor={option}>
              {option}
              <input
                type="radio"
                name={name}
                id={option}
                value={option}
                checked={value === option}
                onChange={() => {
                  handleChange(option)
                  onChange(index)
                }}
              />
              <span className="radiobox-custom" />
            </label>
          </div>
        ))}
      </form>
    </div>
  )
}

import React from "react"

import "./search-engine.scss"

export default ({
  setInputValue,
  setIsSearchEngineVisible,
  data,
  cursor,
  handleOnInputChange,
}) => {
  return (
    <div className="search-engine-container">
      <ul>
        {data.map((el, index) => (
          <li
            key={index}
            className={cursor === index ? "active" : ""}
            onClick={e => {
              console.info("INNER HTML", e.currentTarget.innerHTML)
              setInputValue(e.currentTarget.innerHTML)
              setIsSearchEngineVisible(false)
              if (handleOnInputChange) {
                handleOnInputChange(e.currentTarget.innerHTML)
              }
            }}
          >
            {el.fields.nombreEtiqueta.replace("#", "")}
          </li>
        ))}
      </ul>
    </div>
  )
}

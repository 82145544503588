import React, { useState } from "react"
import CardModal from "../../molecules/card-modal/card-modal"
import illustrator from "../../../assets/images/ai.png"
import photoshop from "../../../assets/images/psd.png"
import font from "../../../assets/images/tt.png"

import "./grid-card.scss"

export default ({ data }) => {
  const { nombre, tipoContenido, etiquetas } = data.fields
  //   const { value } = data.fields.description.content[0].content[0]
  const { description } = data.fields.recurso.fields
  const { url, fileName } = data.fields.recurso.fields.file
  const { contentType } = data.fields.recurso.fields.file
  const [isModalVisible, setIsModalVisible] = useState(false)

  // console.info("ETIQUETAS", etiquetas)
  // console.info("TIPO CONTENIDO", contentType)

  const renderPreview = () => {
    if (contentType.includes("video")) {
      return (
        <video controls width="100%" preload="metadata">
          <source src={url} type={contentType} />
          {/* Sorry, your browser doesn't support embedded videos. */}
        </video>
      )
    } else if (fileName.includes(".ai")) {
      return (
        <img
          src={illustrator}
          alt={description}
          onClick={() => {
            // console.info("CLICKED")
            document.body.classList.add("modal-open")
            setIsModalVisible(true)
          }}
        />
      )
    } else if (fileName.includes(".psd")) {
      return (
        <img
          src={photoshop}
          alt={description}
          onClick={() => {
            // console.info("CLICKED")
            document.body.classList.add("modal-open")
            setIsModalVisible(true)
          }}
        />
      )
    } else if (contentType.includes("font")) {
      return (
        <img
          src={font}
          alt={description}
          onClick={() => {
            // console.info("CLICKED")
            document.body.classList.add("modal-open")
            setIsModalVisible(true)
          }}
        />
      )
    }

    return (
      <img
        src={url}
        alt={description}
        onClick={() => {
          // console.info("CLICKED")
          document.body.classList.add("modal-open")
          setIsModalVisible(true)
        }}
      />
    )
  }

  // {{contentType.includes("video") ? (
  //   <video controls width="100%">
  //     <source src={url} type={contentType} />
  //     {/* Sorry, your browser doesn't support embedded videos. */}
  //   </video>
  // ) : (
  //   <img
  //     src={url}
  //     alt={description}
  //     onClick={() => {
  //       console.info("CLICKED")
  //       setIsModalVisible(true)
  //     }}
  //   />
  // )}}

  return (
    <>
      <div className="grid-card-container">
        <div className="grid-card-content">
          <figure>
            {renderPreview()}
            <h3
              className="name"
              onClick={() => {
                // console.info("CLICKED")
                document.body.classList.add("modal-open")
                setIsModalVisible(true)
              }}
            >
              {nombre}
            </h3>
            <h4 className="format">{tipoContenido}</h4>
            <div className="tag-container">
              {etiquetas
                .filter(el => el.fields.estado)
                .slice(0, 3)
                .map((el, index) => (
                  <div key={index} className="tag">
                    {el.fields.nombreEtiqueta}
                  </div>
                ))}
            </div>
          </figure>
        </div>
      </div>
      {isModalVisible && (
        <CardModal data={data} setIsModalVisible={setIsModalVisible} />
      )}
    </>
  )
}

import React, { useState, useEffect } from "react"

import "./select.scss"

export default ({ defaultValue, options, onChange }) => {
  const [isOpen, setOpen] = useState(false)
  const [value, setValue] = useState(
    defaultValue !== undefined ? defaultValue : "Selecciona una opción"
  )

  const toggleClick = () => {
    setOpen(!isOpen)
  }

  const handleClick = el => {
    if (el === "") {
      setValue("Selecciona una opción")
      onChange("")
    } else {
      setValue(el)
      onChange(el)
    }
    setOpen(!isOpen)
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <div className="select-container">
      <div className="select-wrapper">
        <div className="select-header" onClick={toggleClick}>
          <div className="select-header-title">{value}</div>
          <div className="select-arrow" />
        </div>
        <ul className={isOpen ? "select-list" : "select-list hidden"}>
          {options.map((el, index) => (
            <li
              key={index}
              className="select-list-item"
              onClick={() => handleClick(el)}
            >
              {el}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

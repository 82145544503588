import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import Button from "../../atoms/button/button"
import Input from "../../atoms/input/input"
import Select from "../../atoms/select/select"
import Radiobox from "../../atoms/radiobox/radiobox"
import DragAndDrop from "../../molecules/drag-and-drop/drag-and-drop"
import DragAndDropEdit from "../../molecules/drag-and-drop-edit/drag-and-drop-edit"
import SearchEngine from "../../molecules/search-engine/search-engine"
import Loader from "react-loader-spinner"
/* import "react-loader-spinner/dist/loader/css/react-spinner-loader.css" */

import {
  createEntry,
  editEntry,
  publishEntry,
  getCategoryNames,
  getSubcategoryNames,
  getCountries,
  getTags,
  createAsset,
  getAsset,
  createTags,
} from "../../../services/CMS"

import "./upload-image.scss"

const contentTypeOptions = [
  "Imagen",
  "Fotografía",
  "GIF y videos",
  "Logos y recursos",
  "Tipografías y editables",
]

export default ({ setIsModalVisible, data, category }) => {
  const [dataResourcesFiles, setDataResourcesFiles] = useState([])
  const [hasUploaded, setHasUploaded] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [errorSizeFiles, setErrorSizeFiles] = useState(false)
  const [errorFileValidation, setErrorFileValidation] = useState(false)
  const [loadTag, setLoadTag] = useState(false)

  const [assetId, setAssetId] = useState(data ? data.fields.recurso.sys.id : "")
  const [formState, setFormState] = useState(
    data
      ? {
          name: data.fields.nombre,
          assetId: data.fields.recurso.sys.id,
          description: data.fields.descripcion.content[0].content[0].value,
          country: data.fields.pais.sys.id,
          subcategory: data.fields.subcategoria.sys.id,
          labels: data.fields.etiquetas.map(el => el.sys.id),
          contentType: data.fields.tipoContenido,
        }
      : { contentType: ["Imagen"] }
  )
  const [categories, setCategories] = useState([""])
  const [subcategories, setSubcategories] = useState([""])

  const [countries, setCountries] = useState([""])
  const [categorySelected, setCategorySelected] = useState(
    /* category !== undefined ? category : */ ""
  )
  const [subcategoriesSelected, setSubcategoriesSelected] = useState([""])
  const [tags, setTags] = useState([])

  const [selectedTags, setSelectedTags] = useState(
    data
      ? data.fields.etiquetas
          .filter(el => el.fields.estado)
          .map(el => el.fields.nombreEtiqueta)
      : []
  )
  const [selectedTagIds, setSelectedTagIds] = useState(
    data ? data.fields.etiquetas.map(el => el.sys.id) : []
  )
  const [inputValue, setInputValue] = useState("")
  const [isSearchEngineVisible, setIsSearchEngineVisible] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [noImageSelected, setNoImageSelected] = useState(false)
  const [categoryLabel, setCategoryLabel] = useState([])
  const [subcategoryLabel, setSubcategoryLabel] = useState([])
  const [subcategory, setSubcategory] = useState(
    data !== undefined
      ? data.fields.subcategoria.fields.nombre
      : "Selecciona una opción"
  )
  const [subcategorySelected, setSubcategorySelected] = useState(
    data !== undefined
      ? data.fields.subcategoria.fields.nombre
      : "Selecciona una opción"
  )

  useEffect(() => {
    console.info("FORM STATE", formState)
    console.info("TAGS", tags)
  }, [formState])

  useEffect(() => {
    setFormState({ ...formState, assetId })
  }, [assetId])

  useEffect(() => {
    const getData = async () => {
      const categoryNames = await getCategoryNames()
      console.info("CATEGORIAS", categoryNames)
      // setCategoriesWithId(categoryNames)
      setCategories(categoryNames.items.map(el => el.fields.nombre))

      const subcategoryNames = await getSubcategoryNames()
      console.info("SUBCATEGORIAS", subcategoryNames)
      setSubcategories(subcategoryNames)

      const countryEntries = await getCountries()
      console.info("COUNTRIES", countryEntries.items)
      setCountries(countryEntries.items)

      const tags = await getTags()
      setTags(tags.items)

      // console.info(tags.items)
      // if (data) {
      //   console.info(category.replace(/ /g, "").toLowerCase())
      //   console.info(
      //     "CATEGORY LABEL",
      //     tags.items.filter(
      //       el =>
      //         el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
      //         data.fields.subcategoria.fields.nombre
      //           .replace(/ /g, "")
      //           .toLowerCase()
      //     )
      //   )
      // }
      if (data) {
        setCategoryLabel(
          tags.items.filter(
            el =>
              el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
              category.replace(/ /g, "").toLowerCase()
          )
        )
        setSubcategoryLabel(
          tags.items.filter(
            el =>
              el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
              data.fields.subcategoria.fields.nombre
                .replace(/ /g, "")
                .toLowerCase()
          )
        )
      }
    }

    getData()

    return () => {}
  }, [])

  useEffect(() => {
    setSubcategoriesSelected(
      subcategories.items &&
        subcategories.items
          .filter(el => el.fields.categoria.fields.nombre === categorySelected)
          .map(el => el.fields.nombre)
    )
    return () => {}
  }, [categorySelected])

  const handleKeyDown = e => {
    if (e.keyCode === 27) {
      setIsSearchEngineVisible(false)
    }
  }

  const handleContentTypeChange = option => {
    setFormState({ ...formState, contentType: [contentTypeOptions[option]] })
  }

  const handleCountryChange = country => {
    setFormState({
      ...formState,
      country: countries.filter(el => el.fields.nombreDelPais === country)[0]
        .sys.id,
    })
  }

  const handleCategoryChange = category => {
    const addedTags = tags.filter(el => {
      return (
        el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
        category.replace(/ /g, "").toLowerCase()
      )
    })
    setCategoryLabel(
      tags.filter(el => {
        return (
          el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
          category.replace(/ /g, "").toLowerCase()
        )
      })
    )

    if (addedTags && addedTags.length > 0) {
      if (categoryLabel && categoryLabel.length === 0) {
        setSelectedTagIds(selectedTagIds.concat(addedTags[0].sys.id))
        setFormState({
          ...formState,
          labels: selectedTagIds.concat(addedTags[0].sys.id),
        })
      } else {
        console.info("PREVIOUS TAG", categoryLabel[0].fields.nombreEtiqueta)
        setSelectedTagIds(
          selectedTagIds
            .filter(el => el !== categoryLabel[0].sys.id)
            .concat(addedTags[0].sys.id)
        )
        setFormState({
          ...formState,
          labels: selectedTagIds
            .filter(el => el !== categoryLabel[0].sys.id)
            .concat(addedTags[0].sys.id),
        })
      }
    }

    setCategorySelected(category)
    setSubcategory("Selecciona una opción")
    setSubcategorySelected("Selecciona una opción")
    console.info("DDDD:", subcategory)
    // handleSubcategoryChange("")
  }

  const handleSubcategoryChange = subcategory => {
    const addedTags = tags.filter(el => {
      return (
        el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
        subcategory.replace(/ /g, "").toLowerCase()
      )
    })
    setSubcategoryLabel(
      tags.filter(el => {
        return (
          el.fields.nombreEtiqueta.replace("#", "").toLowerCase() ===
          subcategory.replace(/ /g, "").toLowerCase()
        )
      })
    )

    setSubcategorySelected(subcategory)
    setSubcategory(addedTags[0].fields.nombreEtiqueta.replace("#", ""))

    if (addedTags && addedTags.length > 0) {
      if (subcategoryLabel && subcategoryLabel.length === 0) {
        setSelectedTagIds(selectedTagIds.concat(addedTags[0].sys.id))
        setFormState({
          ...formState,
          labels: selectedTagIds.concat(addedTags[0].sys.id),
          subcategory: subcategories.items.filter(
            el => el.fields.nombre === subcategory
          )[0].sys.id,
        })
      } else {
        console.info("PREVIOUS TAG", subcategoryLabel[0].fields.nombreEtiqueta)
        setSelectedTagIds(
          selectedTagIds
            .filter(el => el !== subcategoryLabel[0].sys.id)
            .concat(addedTags[0].sys.id)
        )
        setFormState({
          ...formState,
          labels: selectedTagIds
            .filter(el => el !== subcategoryLabel[0].sys.id)
            .concat(addedTags[0].sys.id),
          subcategory: subcategories.items.filter(
            el => el.fields.nombre === subcategory
          )[0].sys.id,
        })
      }
    }

    // subcategories &&
    //   setFormState({
    //     ...formState,
    //     subcategory: subcategories.items.filter(
    //       el => el.fields.nombre === subcategory
    //     )[0].sys.id,
    //   })
  }

  const handleOnInputChange = tagName => {
    const addedTags = tags.filter(
      el =>
        el.fields.nombreEtiqueta.replace("#", "") === tagName.replace("#", "")
    )

    console.info("ADDED TAGS", addedTags)

    if (addedTags && addedTags.length > 0) {
      if (!selectedTags.includes(tagName)) {
        setSelectedTags(selectedTags.concat(tagName))
        setSelectedTagIds(selectedTagIds.concat(addedTags[0].sys.id))
        setFormState({
          ...formState,
          labels: selectedTagIds.concat(addedTags[0].sys.id),
        })
      } else {
        console.info("DELETE TAG")
        setSelectedTags(selectedTags.filter(el => el !== tagName))
        setSelectedTagIds(
          selectedTagIds.filter(el => el !== addedTags[0].sys.id)
        )
        setFormState({
          ...formState,
          labels: selectedTagIds.filter(el => el !== addedTags[0].sys.id),
        })
      }
    }
  }

  const handleOnBuscar = e => {
    setInputValue(e)
    setIsSearchEngineVisible(true)
  }

  const uploadFiles = () => {
    setHasLoaded(true)
    const promisesList = []
    dataResourcesFiles.map(resource => {
      promisesList.push(
        createAsset({
          title: "Test",
          description: "Test description",
          contentType: resource.contentType,
          fileName: resource.fileName,
          file: resource.file,
        }).then(asset => {
          createEntry({
            name: formState.name.trim(),
            assetId: asset,
            description: formState.description.trim(),
            countryId: formState.country,
            subcategoryId: formState.subcategory,
            labels: formState.labels,
            contentType: formState.contentType,
          }).then(entryId => {
            console.info("ENTRY ID", entryId)
            publishEntry({ entryId })
            // console.info("CREANDO")
          })
        })
      )
    })
    Promise.all(promisesList).then(responseList => {
      console.log("RESPONSE PROMISE: " + responseList)
      setHasUploaded(true)
      setHasLoaded(false)
    })
  }

  const uploadEditFiles = async () => {
    setHasLoaded(true)
    if (!data) {
      const entryId = await createEntry({
        name: formState.name.trim(),
        assetId,
        description: formState.description.trim(),
        countryId: formState.country,
        subcategoryId: formState.subcategory,
        labels: formState.labels,
        contentType: formState.contentType,
      })
        .then(async () => {
          console.info("ENTRY ID", entryId)
          await publishEntry({ entryId })
            .then(() => {
              // console.info("CREANDO")
              setHasUploaded(true)
            })
            .catch(error => {
              console.log(error)
              setErrorFileValidation(true)
            })
        })
        .catch(error => {
          console.log(error)
          setErrorFileValidation(true)
        })
    } else {
      await editEntry({
        entryId: data.sys.id,
        name: formState.name.trim(),
        assetId: formState.assetId,
        // assetId: data.fields.recurso.sys.id,
        description: formState.description.trim(),
        countryId: formState.country,
        subcategoryId: formState.subcategory,
        labels: formState.labels,
        contentType: formState.contentType,
      })
        .then(() => {
          console.info("EDITANDO")
          setHasUploaded(true)
        })
        .catch(error => {
          console.log(error)
          setErrorFileValidation(true)
        })
    }
    setHasLoaded(false)
  }

  const handleOnUpload = async () => {
    console.info("FFFFFFFFF", selectedTags)
    console.info("FFFFFFFFF", selectedTags !== [])
    if (
      formState.name === undefined ||
      formState.name.trim() === "" ||
      formState.description === undefined ||
      formState.description.trim() === "" ||
      formState.country === undefined ||
      formState.country === "" ||
      formState.subcategory === undefined ||
      formState.subcategory === "" ||
      formState.labels === undefined ||
      formState.labels === [] ||
      formState.contentType === undefined ||
      formState.contentType === [] ||
      subcategory === "Selecciona una opción" ||
      selectedTags.length === 0
    ) {
      console.info("VALIDACIONES", formState)
      setHasErrors(true)
      return
    } else {
      setHasErrors(false)
    }

    if (
      (formState.assetId === undefined || formState.assetId === "") &&
      dataResourcesFiles.length === 0
    ) {
      setNoImageSelected(true)
      return
    } else {
      setNoImageSelected(false)
    }

    if (data === undefined) {
      uploadFiles()
    } else {
      uploadEditFiles()
    }
  }

  const resourcesUpload = dataResources => {
    setDataResourcesFiles(dataResources)
  }

  const createNewTag = async tag => {
    if (loadTag) return
    const validationTag = tags.filter(
      el =>
        el.fields.nombreEtiqueta
          .toLowerCase()
          .replace("#", "")
          .replace(/ /g, "") === inputValue.toLowerCase()
    )

    if (validationTag.length > 0) return

    setLoadTag(true)
    tag = tag.replace("#", "").replace(/ /g, "")

    await createTags(`#${tag}`)
      .then(() => setInputValue(""))
      .catch(error => console.log(error))

    setTimeout(async () => {
      const tags = await getTags()
      setTags(tags.items)
    }, 1000)
    setLoadTag(false)
  }

  return ReactDOM.createPortal(
    <div className="card-modal-container card-modal-container--scrollable">
      {hasUploaded ? (
        <div className="card-modal-wrapper popup">
          <div className="card-modal-content">
            <div className="card-modal-icon-wrapper">
              <div className="card-modal-icon" />
            </div>
            <div className="content-data-icon">
              <div
                className="close-icon"
                onClick={() => {
                  document.body.classList.remove("modal-open")
                  setIsModalVisible(false)
                }}
              />
            </div>
            <h1>
              {data
                ? "¡Recurso actualizado exitosamente!"
                : "¡Recurso subido exitosamente!"}
            </h1>
            <p>
              {data
                ? "Tu imagen se ha actualizado en nuestro banco de imágenes"
                : "Tu imagen se ha añadido a nuestro banco de imágenes, puedes continuar navegando o subir otro recurso"}
            </p>
            <div className="card-modal-buttons">
              {data ? (
                <></>
              ) : (
                <Button
                  name="Subir otro recurso"
                  styleType="primary-inverse"
                  onClick={() => {
                    document.body.classList.remove("modal-open")
                    setSelectedTags([])
                    setInputValue("")
                    setAssetId("")
                    setFormState({ contentType: ["Tipografías y editables"] })
                    setCategorySelected("")
                    setSubcategoriesSelected([""])
                    setSelectedTags([])
                    setSelectedTagIds([])
                    setInputValue("")
                    setIsSearchEngineVisible(false)
                    setHasErrors(false)
                    setNoImageSelected(false)
                    setHasUploaded(false)
                  }}
                />
              )}
              <Button
                name="Aceptar"
                styleType="primary"
                onClick={async () => {
                  document.body.classList.remove("modal-open")
                  setIsModalVisible(false)
                  window.location.href = "/"
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="card-modal-wrapper">
          <div className="card-modal-content">
            <div className="f-container-drag-drop">
              {data !== undefined ? (
                <DragAndDropEdit
                  defaultImage={
                    data !== undefined && {
                      fileName: data.fields.recurso.fields.file.fileName,
                      contentType: data.fields.recurso.fields.file.contentType,
                      url: data.fields.recurso.fields.file.url,
                    }
                  }
                  setAssetId={setAssetId}
                >
                  {noImageSelected && (
                    <div className="content-data-has-errors">
                      <span>*Debes subir un recurso</span>
                    </div>
                  )}
                </DragAndDropEdit>
              ) : (
                <DragAndDrop
                  defaultImage={
                    data !== undefined && {
                      fileName: data.fields.recurso.fields.file.fileName,
                      contentType: data.fields.recurso.fields.file.contentType,
                      url: data.fields.recurso.fields.file.url,
                    }
                  }
                  setAssetId={setAssetId}
                  resourcesUpload={resourcesUpload}
                >
                  {noImageSelected && (
                    <div className="content-data-has-errors">
                      <span>*Debes subir un recurso</span>
                    </div>
                  )}
                </DragAndDrop>
              )}
            </div>

            <div className="content-data">
              <div className="content-data-icon">
                <div
                  className="close-icon"
                  onClick={() => {
                    document.body.classList.remove("modal-open")
                    setIsModalVisible(false)
                  }}
                />
              </div>
              <div className="content-data-name">
                <label>Nombre*</label>
                <Input
                  value={formState.name}
                  placeholder="Nombre para tu imagen"
                  onChange={e => setFormState({ ...formState, name: e })}
                  maxLenght={50}
                />
              </div>
              <div className="content-data-type">
                <span>Tipo de contenido*</span>
                <Radiobox
                  defaultValue={
                    data !== undefined && data.fields.tipoContenido[0]
                    // contentTypeOptions.indexOf(data.fields.tipoContenido[0])
                  }
                  name="upload-content-type"
                  options={contentTypeOptions}
                  onChange={option => handleContentTypeChange(option)}
                />
              </div>
              <div className="content-data-descripcion">
                <span>Descripción*</span>
                <textarea
                  maxLength={400}
                  value={formState.description}
                  placeholder="Escribe aquí..."
                  onChange={e =>
                    setFormState({
                      ...formState,
                      description: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="content-data-country">
                <span>País*</span>
                <Select
                  // defaultValue="País"
                  defaultValue={
                    data !== undefined
                      ? data.fields.pais.fields.nombreDelPais
                      : "Selecciona una opción"
                  }
                  options={
                    countries.length > 1
                      ? countries.map(country => {
                          return country.fields.nombreDelPais
                        })
                      : [""]
                  }
                  onChange={country => handleCountryChange(country)}
                />
              </div>
              <div className="content-data-category">
                <span>Categoría*</span>
                <Select
                  defaultValue={category ? category : "Selecciona una opción"}
                  options={categories}
                  onChange={option => handleCategoryChange(option)}
                />
              </div>
              <div className="content-data-subcategory">
                <span>Subcategoría*</span>
                <Select
                  defaultValue={subcategorySelected}
                  options={
                    subcategoriesSelected && subcategoriesSelected.length > 0
                      ? subcategoriesSelected
                      : [""]
                  }
                  onChange={option => handleSubcategoryChange(option)}
                />
              </div>
              <div className="content-data-hashtag">
                <span>Agregar hashtag*</span>
                <Input
                  placeholder="Buscar"
                  onChange={e => {
                    handleOnBuscar(e)

                    if (e.trim() === "") {
                      setIsSearchEngineVisible(false)
                    } else {
                      setIsSearchEngineVisible(true)
                    }
                  }}
                  onKeyDown={e => handleKeyDown(e)}
                  value={inputValue}
                />
                {inputValue.trim() !== "" && (
                  <>
                    <div
                      className="header-input-container-delete-icon"
                      onClick={() => {
                        setInputValue("")
                        setIsSearchEngineVisible(false)
                      }}
                    />
                    {loadTag ? (
                      <Loader
                        type="Circles"
                        color="#ff409b"
                        height={25}
                        width={25}
                        className="header-input-container-loader"
                      />
                    ) : (
                      <div
                        className="header-input-container-new-icon"
                        onClick={() => {
                          createNewTag(inputValue)
                        }}
                      >
                        Crear hashtag
                      </div>
                    )}
                  </>
                )}
                {isSearchEngineVisible && (
                  <SearchEngine
                    data={tags
                      /* se quito el filtro anterior porque alteraba la busqueda de las etiquetas */
                      .filter(el =>
                        el.fields.nombreEtiqueta
                          .toLowerCase()
                          .replace("#", "")
                          .replace(/ /g, "")
                          .includes(inputValue.toLowerCase())
                      )}
                    // setInputValue={setInputValue}
                    setInputValue={setInputValue}
                    setIsSearchEngineVisible={setIsSearchEngineVisible}
                    handleOnInputChange={el => handleOnInputChange(el)}
                  />
                )}
              </div>
              <div className="content-data-tag-wrapper">
                <div className="tag-wrapper">
                  {/* <div className="tag-container">
                  <div className="tag">#Amigas</div>
                  <div className="tag">#Love</div>
                  <div className="tag">#Multiestilo</div>
                </div> */}
                  <div className="tag-container">
                    {selectedTags &&
                      selectedTags.map((el, index) => (
                        <div
                          key={index}
                          className="tag"
                          onClick={() => {
                            // setSelectedTags(
                            //   selectedTags.filter(tag => tag !== el)
                            // )
                            // setInputValue("")
                            // setInputValue(el)
                            handleOnInputChange(el)
                            console.info("DELETE CLICK", el)
                          }}
                        >
                          #{el.replace("#", "")}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {hasErrors && (
                <div className="content-data-has-errors">
                  <span>*Debes completar todos los campos</span>
                </div>
              )}
              <div className="content-data-button">
                {hasLoaded ? (
                  <Loader
                    type="Circles"
                    color="#FF409B"
                    height={80}
                    width={80}
                  />
                ) : (
                  <Button
                    styleType="primary"
                    name={data ? "Actualizar" : "Subir"}
                    onClick={() => {
                      if (!data) {
                        if (dataResourcesFiles.length > 0) {
                          const validationSizeFiles = dataResourcesFiles.find(
                            file => file.size > 7000000
                          )
                          if (validationSizeFiles) {
                            setErrorSizeFiles(true)
                            return
                          }
                        }
                      }
                      setErrorSizeFiles(false)
                      handleOnUpload()
                    }}
                  />
                )}
              </div>
              {errorSizeFiles && (
                <p className="f-error-file-size">
                  Hay un archivo que pesa mas de 7mb.
                </p>
              )}
              {errorFileValidation && (
                <p className="f-error-file-size">
                  Ocurrió un error al subir los archivos, por favor vuelva a
                  intentarlo.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>,
    document.body
  )
}

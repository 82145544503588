import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Input from "../../atoms/input/input"
import SearchEngine from "../search-engine/search-engine"

import { getTags } from "../../../services/CMS"
import "./header-input-section.scss"

export default ({ mode, title, subtitle, placeholder }) => {
  // const [cursor, setCursor] = useState(0)
  const [tags, setTags] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [isSearchEngineVisible, setIsSearchEngineVisible] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const res = await getTags()
      console.info(res)
      setTags(res.items)
    }

    getData()

    return () => {}
  }, [])

  const handleKeyDown = e => {
    console.info("ENTER", e)
    if (e.key === "Enter") {
      if (inputValue.toLowerCase().trim() !== "") {
        navigate(`/buscar/${inputValue.toLowerCase().trim()}`)
      }
      setIsSearchEngineVisible(false)
    }
    if (e.keyCode === 27) {
      setIsSearchEngineVisible(false)
    }
    // if (e.keyCode === 38 && cursor > 0) {
    //   console.info("CURSOR1")
    //   setCursor(cursor - 1)
    //   // this.setState(prevState => ({
    //   //   cursor: prevState.cursor - 1,
    //   // }))
    // }
    // if (e.keyCode === 40 && cursor < tags.length - 1) {
    //   console.info("CURSOR2")
    //   setCursor(cursor + 1)
    //   // this.setState(prevState => ({
    //   //   cursor: prevState.cursor + 1,
    //   // }))
    // }
  }

  return (
    <div className={`header-input-section ${mode ? "max" : ""}`}>
      {mode === "max" && <h1>{title}</h1>}
      <div className={`header-input-container`}>
        <Input
          value={inputValue}
          placeholder={placeholder}
          onChange={e => {
            setInputValue(e)
            if (e.trim() === "") {
              setIsSearchEngineVisible(false)
            } else {
              setIsSearchEngineVisible(true)
            }
          }}
          onKeyDown={handleKeyDown}
        ></Input>
        {inputValue.trim() !== "" && (
          <div
            className="header-input-container-delete-icon"
            onClick={() => {
              setInputValue("")
              setIsSearchEngineVisible(false)
            }}
          />
        )}
        {isSearchEngineVisible && (
          <SearchEngine
            data={tags.filter(el =>
              el.fields.nombreEtiqueta
                .toLowerCase()
                .replace("#", "")
                .includes(inputValue.toLowerCase().trim())
            )}
            setInputValue={setInputValue}
            setIsSearchEngineVisible={setIsSearchEngineVisible}
            // cursor={cursor}
          />
        )}
        <button
          type="button"
          onClick={() => {
            if (inputValue.toLowerCase().trim() !== "") {
              navigate(`/buscar/${inputValue.toLowerCase().trim()}`)
            }
            setIsSearchEngineVisible(false)
          }}
        />
      </div>
      {mode === "max" && <span>{subtitle}</span>}
    </div>
  )
}

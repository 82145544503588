import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Button from "../../atoms/button/button"
import HeaderInputSection from "../../molecules/header-input-section/header-input-section"
import UploadImage from "../../organisms/upload-image/upload-image"
import logo from "../../../assets/images/logo/96-96.svg"

import "./header.scss"
import { isAdmin } from "../../../utils/getRol"
import userSessionStorage from "../../../services/userSessionStorage"

export default ({ mode = "" }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [role, setRole] = useState("user")
  const session = new userSessionStorage()

  /*  useEffect(() => {
      if (
        sessionStorage.getItem("userSession") !== null &&
        JSON.parse(sessionStorage.getItem("userSession")).rol
      ) {
        setRole(JSON.parse(sessionStorage.getItem("userSession")).rol)
      } else {
        window.location.href = "https://fam-adf-pdn.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=adfs&redirect_uri=https://bancoimagenes.grupofamilia.com/&response_type=TOKEN&client_id=hbpr775hc2cu86hpivokcn8kr&scope=email openid";
      }
    }, [])*/


  useEffect(() => {
    // get ROle
    let idToken = JSON.parse(localStorage.getItem("TOKEN_INFO"))?.idToken
    if (!(session.getToken() === null)) {
      // sesion NOL
      if (isAdmin(idToken)) {
        setRole("admin")
      }

    } else if (sessionStorage.getItem("userSession") !== null &&
      JSON.parse(sessionStorage.getItem("userSession")).rol) {
      // sesion Familia
      setRole(JSON.parse(sessionStorage.getItem("userSession")).rol)

    } else {
      sessionStorage.removeItem("userSession")
      session.destroySession()
      navigate("/login")
    }
  }, [])

  return (
    <header>
      <div className={`header-container ${mode ? "max" : ""}`}>
        <figure className="logo-container" onClick={() => navigate("/")}>
          <img className="logo" alt="Logo Nosotras" src={logo} />
        </figure>
        <HeaderInputSection
          mode={mode}
          title="Todos los recursos que necesitas para tus proyectos"
          subtitle="Ejemplos: Post, Copy, Sophi, Toallas"
          placeholder="Busca todos los recursos"
        />
        {role !== "user" ? (
          <Button
            styleType="secondary"
            name="Subir imagen"
            onClick={() => {
              // const html = document.querySelector("html")
              // html.setAttribute("style", "overflow: hidden")
              document.body.classList.add("modal-open")
              setIsModalVisible(true)
            }}
          />
        ) : (
          <></>
        )}
        {isModalVisible && (
          <UploadImage setIsModalVisible={setIsModalVisible} />
        )}
        <Button
          styleType="secondary"
          icon="exit-arrow-icon"
          iconPosition="right"
          name="Salir"
          onClick={() => {
            sessionStorage.removeItem("userSession")
            session.destroySession()
            window.location.href = "/"
          }}
        />
      </div>
    </header>
  )
}
